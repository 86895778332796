
import "./_gridList.scss"

export const GridList = ({children}) =>{
    return(
        <div className="grid-list">
            {children}
        </div>
    )
}
