import { Art } from "../components/Art"
import { GridContent } from "../components/GridContent"
import { GridHeader } from "../components/GridHeader"
import { GridList } from "../components/GridList"
import { NodeContent } from "../components/NodeContent"


export const ContentfulComponent = ({contentfulData}) =>{
    switch(contentfulData?.sys?.contentType?.sys?.id){
        case "gridHeader": return(        
            <GridHeader title={contentfulData.fields.title} />
        )
        case "art": return(
            <Art contentfulData={contentfulData} ></Art>
        )
        case "gridList": return(
            <GridList >
                {contentfulData.fields.gridItems.map((item,index) => {
                    return <ContentfulComponent key={index} contentfulData={item}/>
                })}
            </GridList>
        )
        case "gridContent": {
            return(
            <GridContent>
                <NodeContent contentfulData={contentfulData.fields.gridContent} />
            </GridContent>
        )}
        case "textContent": return(
            <NodeContent contentfulData={contentfulData.fields.text} />
        )
        case "page": return(
            <>
                {contentfulData.fields.content.map((item,index)=>{
                    return (<ContentfulComponent key={index} contentfulData={item}/>)
                })}
           </>
        )
        default: return null;    
    }
}