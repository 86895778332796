import { useState } from "react";
import "./_artInfo.scss"

export const ArtInfo = ({contentfulFields}) =>{
    if(!contentfulFields.prices){
        return
    }
        const numberFormat = new Intl.NumberFormat("sv-SE", { style: "currency", currency: "SEK" });
    return (
        
        <div className="art-info">
            <h3>Priser</h3>
           {
                contentfulFields?.prices.map( t => <p>{t.fields?.size} {t.fields?.price  ? numberFormat.format(t.fields?.price): "—"}</p>)
            }
            <span>För mer information om leverans eller övriga frågor kontakta <a href="mail:andreaestblom@hotmail.com">andreaestblom@hotmail.com</a></span>
            
        </div>
    )
};