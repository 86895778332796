import { Container } from "../components/Container";
import React, {useEffect, useState} from "react";
import ApiService from "../utils/ApiService"
import { ContentfulComponent } from "../utils/ContentfulComponent";

export const HomePage = React.forwardRef((props,forwardRef) => {
    const [homePage, setHomePage] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const result = await ApiService.get("entries/7AobwXemDzT8Ysa4v9IlGl?include=10")
            if(result.data.sys.updatedAt !== homePage?.sys?.updatedAt){
                setHomePage(result.data);
            }            
        }
        fetchData();
    }, [])

	return (
            <Container ref={forwardRef}>               
                <ContentfulComponent contentfulData={homePage}></ContentfulComponent>       
            </Container>

	)
});