
import "./_modal.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faXmark } from '@fortawesome/free-solid-svg-icons'
export const Modal = ({style,children}) =>{
    return(
        <div style={style} className="modal" tabIndex={0}>
            {children}
            <div className="modal-close"><FontAwesomeIcon size="3x" icon={faXmark} /></div>
        </div>
    )
}
