import "./variables.scss";
import "./scss/main.scss";
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { HomePage } from "./pages/HomePage"
import { AboutPage } from "./pages/AboutPage"
import { Header } from "./components/Header";
import { Layout } from "./pages/Layout";
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { createContext, useState } from "react";

const routes = [
  { path: '/', name: 'Start', Element: HomePage },
  { path: '/om', name: 'Om', Element: AboutPage },
]

export const ModalContext = createContext()

const AnimatedSwitch = () => {
  const location = useLocation();

  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.key}
        timeout={300}
        classNames={"fade"}
      >
        <Routes location={location}>
          <Route path="/" element={
            <Layout />
          }>
            {routes.map(({ path, Element }) => (
              <Route key={path} exact path={path} element={
                <Element />
              } />
            ))}
          </Route>
        </Routes>
      </CSSTransition>

    </TransitionGroup>
  )

}

function App() {

  window.onload = function() {
    document.addEventListener("contextmenu", function(e){
      e.preventDefault();
    }, false);
    document.addEventListener("keydown", function(e) {
      // "S" key + macOS
      if (e.key === "S" && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
        disabledEvent(e);
      }
    }, false);
    function disabledEvent(e){
      if (e.stopPropagation){
        e.stopPropagation();
      } else if (window.event){
        window.event.cancelBubble = true;
      }
      e.preventDefault();
      return false;
    }
  }
  const [modalIsOpen, setModalIsOpen] = useState(false)
  
  return (
    <ModalContext.Provider value={{ modalIsOpen, setModalIsOpen }}>
      <div className={`page`}>
        <BrowserRouter>
          <Header />
          <AnimatedSwitch />
        </BrowserRouter>
      </div>
    </ModalContext.Provider>

  );
}

export default App;
