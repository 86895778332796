
import "./_container.scss"
import React  from "react"
export const Container = React.forwardRef(({children,className},forwardRef) =>{
    return(
        <div ref={forwardRef} className={`container ${className ?? ""}`}>
            <div className="content"> 
                {children}
            </div>
        </div>
    )
})
