import { Container } from "../components/Container";
import React, { useEffect, useState } from "react";
import "./_aboutPage.scss"
import { ContentfulComponent } from "../utils/ContentfulComponent";
import ApiService from "../utils/ApiService";

export const AboutPage = React.forwardRef((props,forwardRef) => {
	const [aboutPage, setAboutPage] = useState();

        useEffect(() => {
                const fetchData = async () => {
                const result = await ApiService.get("entries/7Hh6AAuDmu5084z6MYXBkk?include=10")
                if(result.data.sys.updatedAt !== aboutPage?.sys?.updatedAt){
                        setAboutPage(result.data);
                }            
                }
                fetchData();
        }, [])

	return (
            <Container className="about-page" ref={forwardRef}>               
                <ContentfulComponent contentfulData={aboutPage}></ContentfulComponent>       
            </Container>
	)
});