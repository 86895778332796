import { NavLink, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram  } from '@fortawesome/free-brands-svg-icons'
import "./_header.scss"

export const Header = () =>{
    const location = useLocation();
    const handleClick = (e,path) => {
        const linkDisabled  = location.pathname === path
        if(linkDisabled) e.preventDefault()
    }
    return(
        <div id="header" className="header">
            <h1>Estblom.se</h1>
            <menu>
                <li><NavLink onClick={(event) => handleClick(event,"/")} className={({ isActive }) => (isActive ? 'active' : 'inactive')}  to="/">Konstverk</NavLink></li>
                <li><span>|</span></li>
                <li><NavLink onClick={(event) => handleClick(event,"/om")} className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/om">Om</NavLink></li>
                <li><span>|</span></li>
                <li><a rel="noreferrer" className={"instagram"} target={"_blank"} href="https://www.instagram.com/craftinganni/"><FontAwesomeIcon icon={faInstagram} /></a></li>
            </menu>
        </div>
    )
}
