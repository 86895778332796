import { useState } from "react";
import "./_artImage.scss"

export const ArtImage = ({src,alt,style,width, height}) =>{
    const [didLoad, setLoad] = useState(false)
    return (
        <>
            <div className="transparent-overlay"></div>
            <div style={{width:width, height:height,display:!didLoad ? "inherit":"none"}} alt={alt} className="image-loading" />
            <img style={{...style,display:didLoad ? "inherit":"none"}} src={src} alt={alt} onLoad={() => setLoad(true)}/>
        </>
    )
};